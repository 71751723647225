module.exports = [{
      plugin: require('/Users/cbaucom/Sites/react/gatsby sites/crbaucom-g-v2/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1080},
    },{
      plugin: require('/Users/cbaucom/Sites/react/gatsby sites/crbaucom-g-v2/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/cbaucom/Sites/react/gatsby sites/crbaucom-g-v2/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-77649451-3","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/cbaucom/Sites/react/gatsby sites/crbaucom-g-v2/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/cbaucom/Sites/react/gatsby sites/crbaucom-g-v2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
